import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import { Link, graphql } from "gatsby";
import HikakuTable from "components/hikakuTableLoadable";
import AccentBox from "components/accentBox";
import Image from "components/image";
import Socials from "components/socials";
import BasicTable from "components/basicTable";
import BenchMark from 'components/cpu/benchmark';
import CpuMatsubi from 'components/cpu/cpu_matsubi';
import HowtoFilter from 'components/cpu/howtofilter';
import TableDescription from 'components/cpu/table_description';
import PassMark from 'components/cpu/passmark';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "cpu/apple"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    cover: file(relativePath: { eq: "cover/cpu/apple-cpu.png"}) { ...eyecatchImg },
    allAppleJson {
      nodes {
        name
        pm
        pms
        year
        clock
        core
        thread
        tdp
        price
        cospa
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Apple CPU 比較表 | MacやiPadのCPU性能を比較`}</h1>
    <time itemProp="datePublished" dateTime="Sat Oct 12 2024 00:56:48 GMT+0900 (日本標準時)">更新日:2024年10月12日</time>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="cover" alt="Apple CPU 比較表" mdxType="Image" />
    <p>{`Appleの縦に長い商品説明を見ると`}<em parentName="p">{`「かつてない衝撃的なパワー」`}</em>{`、`}<em parentName="p">{`「この速さはずっと続く」`}</em>{`、`}<em parentName="p">{`「驚異的なパワー、内蔵」`}</em>{`といったパワーワードがずらりと並び、さらに質の悪いことにすべての製品で同じような内容のため比較ができないという問題がある。`}</p>
    <p>{`そこで本ページでは`}<strong parentName="p"><em parentName="strong">{`Mac、iPad、iPhoneで使われるAppleのCPUを総合的な能力を計測するベンチマークであるPassMarkのスコアで比較`}</em></strong>{`し、使用目的に合わせた製品選びができることを目指す。`}</p>
    <h2 {...{
      "id": "Apple-CPU-性能比較表",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Apple-CPU-%E6%80%A7%E8%83%BD%E6%AF%94%E8%BC%83%E8%A1%A8",
        "aria-label": "Apple CPU 性能比較表 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Apple CPU 性能比較表`}</h2>
    <p>{`それでは早速比較表を紹介する。PassMarkの高い順に並べているが、項目をクリックするとソートできるので名前順など自由に並び変えて特徴を分析してほしい。`}</p>
    <p>{`なお、一般的なPassMarkの基準は次のようになる。MACはゲーム目的には向いていないため、クリエイティブ用途で参考にしてもらえばよいだろう。`}</p>
    <PassMark mdxType="PassMark" />
    <BenchMark mdxType="BenchMark" />
    <HikakuTable {...props} noFillter sort={{
      fieldName: 'name'
    }} cpuData={props.data.allAppleJson.nodes} fieldArray={[{
      id: 'name',
      filterType: 'string'
    }, {
      id: 'pm'
    }, {
      id: 'pms'
    }, {
      id: 'core'
    }, {
      id: 'thread'
    }, {
      id: 'clock'
    }, {
      id: 'year'
    }]} mdxType="HikakuTable" />
    <h2 {...{
      "id": "Apple-CPUの特徴",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#Apple-CPU%E3%81%AE%E7%89%B9%E5%BE%B4",
        "aria-label": "Apple CPUの特徴 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Apple CPUの特徴`}</h2>
    <p>{`次に上記の表からわかる特徴を簡単にまとめていく。`}</p>
    <h3 {...{
      "id": "Mシリーズはクリエイティビティによりコア数を選ぶ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#M%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA%E3%81%AF%E3%82%AF%E3%83%AA%E3%82%A8%E3%82%A4%E3%83%86%E3%82%A3%E3%83%93%E3%83%86%E3%82%A3%E3%81%AB%E3%82%88%E3%82%8A%E3%82%B3%E3%82%A2%E6%95%B0%E3%82%92%E9%81%B8%E3%81%B6",
        "aria-label": "Mシリーズはクリエイティビティによりコア数を選ぶ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mシリーズはクリエイティビティによりコア数を選ぶ`}</h3>
    <p>{`Mシリーズは主にコア数により`}<em parentName="p">{`M2 Ultra`}</em>{`、`}<em parentName="p">{`M2 Pro`}</em>{`、`}<em parentName="p">{`M2 Max`}</em>{`、`}<em parentName="p">{`M2通常版`}</em>{`と名前が変わる。`}</p>
    <p>{`コアごとの性能は最大周波数の細かい違いこそあるもののほぼ同等であるためシングルコアスコアは同じシリーズではほとんど変わらない。すなわち`}<strong parentName="p"><em parentName="strong">{`Webブラウジングやアプリのサクサク感といった使用感にはシリーズ内では差がない`}</em></strong>{`といえる。`}</p>
    <p>{`よって`}<strong parentName="p"><em parentName="strong">{`M2の通常版にするべきか、Proにすべきかという判断はクリエイティブな作業をどの程度快適にしたいか`}</em></strong>{`で決まり、例えばコア数が増えれば次のようなことを高速化することが可能である。`}</p>
    <BasicTable mdxType="BasicTable">
  <thead>
    <tr>
      <th>用途</th>
      <th>説明</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>動画編集</td>
      <td>テロップの自動生成、シーン切り替わりの検出など</td>
    </tr>
    <tr>
      <td>ビデオのトランスコード</td>
      <td>動画を別のフォーマットや画質に変換、Youtube動画のアップロード時など</td>
    </tr>
    <tr>
      <td>画像のアップスケーリングや合成</td>
      <td>AI学習による画像の高画質化</td>
    </tr>
    <tr>
      <td>XCodeのビルド</td>
      <td>iOSアプリをプログラミングで作成した時の成果物生成</td>
    </tr>
  </tbody>
    </BasicTable>
    <p>{`まとめると動画像編集やプログラミングなどのクリエイティブな作業に対しては、高負荷な作業を伴うことが多いので、そこをいかに快適に行なえるかということがポイントとなってくる。`}</p>
    <p>{`この辺の感覚はある程度使ってみないと分からないものであるが、結局どれがおすすめ？ということになると、`}<strong parentName="p"><em parentName="strong">{`仕事で使うレベルで使いこなしたいならばProを搭載すべきであるし、趣味の範囲で行ないたいならば通常版を搭載しておけばよい`}</em></strong>{`だろう。`}</p>
    <h3 {...{
      "id": "Apple-Aシリーズは普段使いとしてもA14以上がおすすめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#Apple-A%E3%82%B7%E3%83%AA%E3%83%BC%E3%82%BA%E3%81%AF%E6%99%AE%E6%AE%B5%E4%BD%BF%E3%81%84%E3%81%A8%E3%81%97%E3%81%A6%E3%82%82A14%E4%BB%A5%E4%B8%8A%E3%81%8C%E3%81%8A%E3%81%99%E3%81%99%E3%82%81",
        "aria-label": "Apple Aシリーズは普段使いとしてもA14以上がおすすめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Apple Aシリーズは普段使いとしてもA14以上がおすすめ`}</h3>
    <p><a parentName="p" {...{
        "href": "https://amzn.to/45BDJOO",
        "target": "_blank",
        "rel": "nofollow noopener"
      }}>{`第10世代のiPad`}</a>{`やiPhone 12などに搭載されているのがApple A14 Bionicであるが、`}<strong parentName="p"><em parentName="strong">{`A14はA13と比較して一気にシングルコアスコアが伸びてきている`}</em></strong>{`。これによりWebブラウジングやアプリ操作が体感が変わるレベルでサクサクになる。`}</p>
    <p>{`それでかつ日常的に楽しむには十分なPassMark結果を出していることから、高負荷なことを行わない大抵のユーザにとって最善な選択肢となるであろう。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      